import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import roomStoreModule from '../roomStoreModule';

export default function useRoomList() {
  const ROOM_STORE_MODULE_NAME = 'room';

  // Register module
  if (!store.hasModule(ROOM_STORE_MODULE_NAME)) {
    store.registerModule(ROOM_STORE_MODULE_NAME, roomStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(ROOM_STORE_MODULE_NAME)) {
      store.unregisterModule(ROOM_STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    id: 0,
    apartment: null,
    floor: null,
    name: '',
    price: null,
    deposit: null,
    size: null,
    active: true,
    maxTenants: 0,
    invoiceTemplate: null,
    contractTemplate: null,
  };
  const { t } = useI18nUtils();
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'room') && !vm.$can('delete', 'room');

  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: t('Tên phòng'),
      field: 'name',
    },
    {
      label: t('Giá thuê'),
      field: 'price',
      type: 'number',
      width: '140px',
    },
    {
      label: t('Đặt cọc'),
      field: 'deposit',
      type: 'number',
      width: '140px',
    },
    {
      label: t('Diện tích'),
      field: 'size',
      type: 'number',
      width: '100px',
    },
    {
      label: t('Trạng thái'),
      field: 'status',
    },
    {
      label: t('Hoạt động'),
      field: 'active',
      tdClass: 'text-center',
      type: 'boolean',
      width: '110px',
    },

  ];
  const rows = ref([]);
  const analytic = ref(null);
  // filter
  const active = ref(null);
  const status = ref(null);
  const apartment = ref(null);
  const floor = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchRooms = () => {
    isLoading.value = true;
    store
      .dispatch('room/fetchRooms', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const fetchAnalytic = () => {
    store
      .dispatch('room/fetchAnalytic', serverParams.value)
      .then(response => {
        analytic.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchRooms();
    fetchAnalytic();
  };

  const downloadImportTemplate = data => {
    const postData = data ? { filter: { apartmentId: data && data.apartment && data.apartment.id ? data.apartment.id : null } } : {};
    store
      .dispatch('room/generateImportRoomTemplate', postData)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('room/importRooms', formData)
      .then(() => {
        // fetchData();
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('room/exportRooms', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteRooms = rooms => {
    store
      .dispatch('room/deleteRooms', {
        ids: rooms.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const onViewDetailItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(active, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.active = val.value;
    } else {
      delete filter.active;
    }
    updateParams({ filter });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(floor, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.floorId = val.id;
    } else {
      delete filter.floorId;
    }
    updateParams({ filter });
  });
  watch(status, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.status = val.id;
    } else {
      delete filter.status;
    }
    updateParams({ filter });
  });

  // UI
  const resolveRoomStatus = val => {
    if (val.numberActiveBeds > 0 && val.beds.length > 0) {
      const beds = val.beds.filter(_obj => _obj.active);
      const numberInactive = val.beds.filter(_obj => !_obj.active).length || 0;
      const numberRent = beds.filter(_obj => parseInt(_obj.status.id, 10) >= 3).length || 0;
      const numberEmpty = beds.filter(_obj => parseInt(_obj.status.id, 10) === 1).length || 0;
      const numberDeposit = beds.filter(_obj => parseInt(_obj.status.id, 10) === 2).length || 0;

      if (beds.length > 0) {
        const statusArr = [];
        if (numberRent > 0) {
          statusArr.push({ title: `Đang thuê ${numberRent}`, variant: 'primary' });
        }
        if (numberEmpty > 0) {
          statusArr.push({ title: `Đang trống ${numberEmpty}`, variant: 'secondary' });
        }
        if (numberDeposit > 0) {
          statusArr.push({ title: `Đang cọc ${numberDeposit}`, variant: 'warning' });
        }
        if (numberInactive > 0) {
          statusArr.push({ title: `Ngưng SD ${numberDeposit}`, variant: 'danger' });
        }

        return statusArr;
      }
    }

    return [val.status];
  };

  return {
    analytic,
    item,
    columns,
    rows,
    active,
    apartment,
    floor,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    status,
    fetchData,
    fetchRooms,
    deleteRooms,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    exportData,
    onViewDetailItem,
    resolveRoomStatus,
    downloadImportTemplate,
    importData,
    t,
  };
}
